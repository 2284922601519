import { createSlice } from "@reduxjs/toolkit";

const postsSlice = createSlice({
	name: "posts",
	initialState: { posts: [], error: null }, //has to be an object of something. tried directly an array, didnt work
	//extraReducers would be here aka thunk
	reducers: {
		setPostsSuccess: (state, action) => {
			state.posts = action.payload;
			state.error = null;
		},
		setPostsFail: (state, action) => {
			state.posts = [];
			state.error = action.payload;
		},
	},
});

export const { setPostsSuccess, setPostsFail } = postsSlice.actions;

// posts is from store.js, not name above. second posts is from state.posts above.
export const selectPosts = (state) => {
	return state.posts.posts;
};

export default postsSlice.reducer;
